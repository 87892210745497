<template>
  <div class="news-box">
    <div class="news">
      <div @click.stop="showCode"  v-for="item in list" :key="item.id" class="news-item">
        <img src="../assets/vip.png" class="tag" alt="">
        <div class="title">{{item.title}}</div>
        <div class="detail">
          <div class="author">作者：{{item.author}}</div>
          <div class="time">{{item.created_at}}</div>
        </div>
        <div style="color: rgb(232, 80, 6)">
          价格：&yen;10
        </div>
      </div>
    </div>
    <div @click.stop="isShowModal = false" v-show="isShowModal" class="pic-modal">
      <img src="../assets/paycode.jpg" alt="">
    </div>
  </div>
</template>
<script>
import Axios from 'axios'
export default {
  data () {
    return {
      params: {
        page: 1,
        per_page: 100
      },
      list: [],
      isShowModal: false
    }
  },
  created () {
    this.getList()
  },
  methods: {
    showCode () {
      this.isShowModal = true
    },
    getList () {
      Axios.post('http://api_2021.sinmore.com.cn/ajax/news-list', this.params).then(res => {
        const arr = res.data.data.data.filter(item => { return item.id >= 294 && item.id <= 297 })
        const arr1 = res.data.data.data.filter(item => { return item.id < 294 || item.id > 297 })
        const list = arr.concat(arr1.splice(0, 11))
        this.list = list
        // let arr = []
        // res.data.data.data.forEach((item, index) => {
        //   if (item.id === 297 || item.id === 296 || item.id === 295 || item.id === 294) {
        //     arr = arr.concat(res.data.data.data.splice(index, 1))
        //   }
        // })
        console.log(arr)
      })
    },
    jumpDetail (id) {
      this.$router.push({ path: '/detail', query: { id: id } })
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes fade {
  from {
    background-color: rgba(0,0,0,0);
  }
  to {
    background-color: rgba(0,0,0,0.6);
  }
}
@keyframes scale {
  from {
    transform: scale(.5);
  }
  to {
    transform: scale(1);
  }
}
.news-box {
  width: 100%;
  display: flex;
  justify-content: center;
  .pic-modal {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0);
    animation: fade .5s forwards;
    img {
      max-width: 60%;
      max-height: 60%;
      animation: scale .5s forwards;
    }
  }
  .news {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 10px;
    .news-item {
        position: relative;
        cursor: pointer;
        width: 32%;
        box-shadow: 0px 4px 17px 0px #e7e6e6;
        margin-bottom: 20px;
        padding: 30px 20px 20px;
        height: 140px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .tag {
          position: absolute;
          left: 0;
          top: 0;
          width: 50px;
        }
        .title {
          font-size: 16px;
          font-weight: bold;
          height: 44px;
          line-height: 22px;
          display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
        }
        .detail {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
    }
  }
}
</style>
