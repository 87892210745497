<template>
  <div class="detail-box">
    <div class="detail">
      <div class="title">{{detail.title}}</div>
      <div class="details">
        <div class="author">作者： {{detail.author}}</div>
        <div class="time">时间：{{detail.created_at}}</div>
      </div>
      <div class="content" v-html="detail.rich_text"></div>
    </div>
  </div>
</template>
<script>
import Axios from 'axios'
export default {
  data () {
    return {
      id: '',
      detail: {}
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    getDetail () {
      console.log(this.id)
      Axios.post('http://api_2021.sinmore.com.cn/api/content/news-detail/' + this.id).then(res => {
        console.log(res)
        this.detail = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.detail-box {
  width: 100%;
  display: flex;
  justify-content: center;
  .detail {
    width: 1200px;
    padding: 30px 0;
    .title {
      font-size: 30px;
    }
    .details {
      display: flex;
      align-items: center;
      color: #666666;
      font-size: 14px;
      margin-top: 15px;
    }
    .content {
      margin-top: 20px;
      section {
        img {
          display: block!important;
          margin: 0 auto!important;
        }
      }
    }
  }
}
</style>
